import React, { useState, useEffect } from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoLight, LatoRegular } from "../components/Text";
import Request from "../assets/images/request.png";
import ContactUsPhone from "../assets/images/contact-us-phone.png";
import SocialMedia from "../components/SocialMedia";
import { getDeviceType } from "../tools/Interface";
import { Wrapper } from "../components/Wrapper";
import Footer from "../components/Footer";
import { BsWhatsapp } from "react-icons/bs";
import { contactDecider } from "../tools/decider";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";
// import { ScrollView } from "react-native-web";

export default function RequestProductScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });


  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const number = contactDecider("HermesConsignment");
    setPhoneNumber(number);
  }, []);

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <View style={{ marginBottom: 100 }}>
          {Boolean(isPhone) && (
            <View
              style={{
                marginVertical: 48,
                marginHorizontal: gap,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LatoRegular
                style={{ fontSize: 24, lineHeight: 32, marginBottom: 8 }}
              >
                We’ll Find What You Wish
              </LatoRegular>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey6C,
                }}
              >
                Exclusive shopping concierge service to source your luxury wishlist. Let us know your request and we will shop them for you!
              </LatoRegular>
            </View>
          )}
          <View
            style={{
              flexDirection: isPhone ? "column" : "row-reverse",
              alignItems: "center",
              justifyContent: "space-around",
              marginBottom: 48,
              marginHorizontal: gap,
              marginTop: isPhone ? 0 : 100,
            }}
          >
            <View
              style={{
                width: isPhone ? containerWidth : containerWidth / 2 - 40,
                alignItems: "center",
                borderBottomColor: colors.greyE6,
                borderBottomWidth: isPhone ? 1 : 0,
                marginBottom: isPhone ? 16 : 0,
                paddingBottom: isPhone ? 24 : 0,
              }}
            >
              <Image
                source={Request}
                // alt={alt}
                style={{
                  height: isPhone ? containerWidth : containerWidth / 2 - 40,
                  width: isPhone ? containerWidth : containerWidth / 2 - 40,
                }}
                defaultSource={require("../assets/images/spinning-loading.gif")}
                resizeMode={"contain"}
              />
            </View>
            <View
              style={{
                width: isPhone ? containerWidth : containerWidth / 2 - 40,
              }}
            >
              {Boolean(!isPhone) && (
                <View
                  style={{
                    paddingVertical: 12,
                    borderBottomColor: colors.greyE6,
                    borderBottomWidth: 1,
                    marginBottom: 32,
                  }}
                >
                  <LatoRegular
                    style={{ fontSize: 24, lineHeight: 32, marginBottom: 16 }}
                  >
                    We’ll Find What You Wish
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                    }}
                  >
                    Exclusive shopping concierge service to source your luxury wishlist. Let us know your request and we will shop them for you!
                  </LatoRegular>
                </View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: isPhone ? 16 : 24,
                  marginBottom: isPhone ? 16 : 24,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                >
                  CONTACT OUR CLIENT ADVISOR
                </LatoRegular>
                <a
                  href={`https://web.whatsapp.com/send?phone=${phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecorationLine: "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 28,
                        height: 28,
                        backgroundColor: "#25d366",
                        padding: 6,
                        borderRadius: 1000,
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 4,
                      }}
                    >
                      <BsWhatsapp size={28} color={colors.whiteFF} />
                    </View>
                  </View>
                </a>
              </View>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginBottom: isPhone ? 16 : 24,
                }}
              >
                INSTAGRAM
              </LatoRegular>
              <SocialMedia color={colors.grey33} />
              <View
                style={{
                  flexDirection: isPhone ? "col" : "row",
                  alignItems: isPhone ? "left" : "center",
                  justifyContent: "space-between",
                  gap: 16,
                  paddingTop: isPhone ? 16 : 24,
                  marginTop: isPhone ? 16 : 24,
                  borderTopColor: colors.greyE6,
                  borderTopWidth: 1,
                  paddingBottom: isPhone ? 16 : 24,
                  marginBottom: isPhone ? 16 : 24,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                >
                  ITEM REQUEST FORM
                </LatoRegular>
                <Buttons
                    label={"Request Item"}
                    isBlack
                    onPress={() =>
                      navigate("/account/request", {
                        state: { idx: 3, index: 0 },
                      })}
                    width={isPhone ? width - 32 : 250}
                  />
            </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
