import React from "react";
import { Image, View, useWindowDimensions } from "react-native-web";
import { getDeviceType } from "../../tools/Interface";
import Buttons from "../Buttons";
import Office from "../../assets/images/new-office.jpg";
import colors from "../../assets/colors/colors";
import { LatoBold, LatoRegular } from "../Text";
import { useNavigate } from "react-router";

function VisitUs({ justText = false }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  return (
    <View style={{ flexDirection: isTablet ? "column" : "row" }}>
      {Boolean(!justText) && (
        <Image
          source={Office}
          style={{
            width: isTablet ? width : 0.67 * width,
            height: ((isTablet ? width : 0.67 * width) * 720) / 1279,
          }}
          resizeMode="contain"
        />
      )}
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.green5B,
          paddingHorizontal: 60,
          paddingVertical: 80,
        }}
      >
        <LatoBold
          style={{
            fontSize: isTablet ? 16 : width < 1500 ? 24 : 32,
            lineHeight: isTablet ? 24 : width < 1500 ? 32 : 40,
            color: colors.whiteFF,
            marginBottom: 16,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          {justText
            ? "Want to personally drop off your bags in store?"
            : "Want to see the products in person?"}
        </LatoBold>
        <LatoRegular
          style={{
            fontSize: isTablet ? 14 : width < 1500 ? 16 : 20,
            lineHeight: isTablet ? 20 : width < 1500 ? 24 : 28,
            color: colors.whiteFF,
            marginBottom: 32,
            textAlign: "center",
          }}
        >
          {justText
            ? "Ready to sell or trade your items? Bring accepted styles to Belle&Kate Room store and receive the most competitive price."
            : isPhone
            ? "Browse our luxury goods in person with the help of our dedicated client advisors."
            : "Feel free to walk in to browse our luxury goods in person with the help of our dedicated client advisors. Hermes viewing requires an appointment."}
        </LatoRegular>
        <Buttons
          onPress={() => navigate("/book-appointment")}
          label={"BOOK APPOINTMENT"}
          isBlack
          containerStyle={{ borderRadius: 2 }}
          width={210}
          height={40}
          backgroundColor={colors.black1A}
        />
      </View>
    </View>
  );
}

export default VisitUs;
