import { sentenceCase } from "change-case";
import colors from "../assets/colors/colors";

export const colorDecider = (color) => {
  const engineeredName = color.replace(" ", "");

  if (engineeredName === "White") return colors.whiteFF;
  else if (engineeredName === "Black") return colors.black00;
  else if (engineeredName === "Grey") return colors.grey6C;
  else if (engineeredName === "Brown") return colors.brown6B;
  else if (engineeredName === "Red") return colors.redAD;
  else if (engineeredName === "Pink") return colors.pinkFF;
  else if (engineeredName === "Orange") return colors.orangeFF;
  else if (engineeredName === "Yellow") return colors.yellowFF;
  else if (engineeredName === "Off-White") return colors.whiteE8;
  else if (engineeredName === "Green") return colors.green03;
  else if (engineeredName === "Blue") return colors.blue22;
  else if (engineeredName === "Purple") return colors.purple72;
  else if (engineeredName === "Gold") return colors.goldD6;
  else if (engineeredName === "Silver") return colors.silverEF;
};

export const stateIndicatorDecider = (state) => {
  if (state === "waiting_for_payment") return colors.greyBB;
  else if (state === "waiting_for_confirmation") return colors.green5B;
  else if (state === "waiting_for_shipment") return colors.orangeF6;
  else if (state === "on_delivery") return colors.yellowCC;
  else if (state === "completed") return colors.green46;
  else if (state === "cancelled" || state === "failed") return colors.redB6;
  else if (state === "waiting_for_refund" || state === "refund_requested")
    return colors.greyBB;
  else if (state === "refunded") return colors.brown6B;
  else if (state === "waiting_for_return") return colors.purple72;
  else if (state === "returned") return colors.green03;
};

export const pickupStateColorDecider = (state, received = false) => {
  if (received) return colors.green46;
  if (state === "requested") return colors.orangeF6;
  if (state === "confirmed" || state === "waiting_for_pickup")
    return colors.yellowCC;
  if (state === "cancelled" || state === "rejected") return colors.redB6;

  return colors.greyBB;
};

export const consignmentStateColorDecider = (state) => {
  if (state === "waiting_for_live") return colors.green46;
  if (state === "cancelled" || state === "failed") return colors.redB6;
  if (
    state === "on_sale" ||
    state === "completed" ||
    state === "waiting_for_disbursement" ||
    state === "completed_without_deal"
  )
    return colors.green46;
  if (state === "waiting_for_appraisal" || state === "appraisal_negotiation")
    return colors.orangeF6;

  return colors.greyBB;
};

export const stateMaskingDecider = (state) => {
  if (state === "waiting_for_payment") return "Waiting for Payment";
  else if (state === "waiting_for_confirmation")
    return "Waiting for Confirmation";
  else if (state === "waiting_for_shipment") return "Processed";
  else if (state === "on_delivery") return "Shipping";
  else if (state === "completed") return "Completed";
  else if (state === "cancelled") return "Cancelled";
  else if (state === "waiting_for_refund") return "Refund On Progress";
  else if (state === "refunded") return "Refunded";
  else if (state === "refund_requested") return "Refund Requested";
  else if (state === "waiting_for_return") return "Waiting for Return";
  else if (state === "returned") return "Returned";
  else if (state === "failed") return "Failed";

  return sentenceCase(state || "");
};

export const pickupStateDecider = (state, item = false, received = false) => {
  if (state === "requested") return "Request Submitted";
  if (state === "cancelled") return "Request Cancelled";
  if (state === "confirmed") {
    if (received) return "Items received";
    return "Request reviewed";
  }
  if (state === "received") return "Item Received";
  if (state === "rejected") return item ? "Item Rejected" : "Request Rejected";
  if (state === "waiting_for_pickup") return "Request Submitted";

  return sentenceCase(state);
};

export const pickupStatusDecider = (state, received = false) => {
  if (state === "requested")
    return "Items under review by Belle&Kate client advisors\nEstimated- 2-3 days";
  if (state === "cancelled") return "Cancelled";
  if (state === "confirmed") {
    if (received)
      return "Your bags are currently in store and under authentication process";
    return "Items reviewed. Please see details for further information";
  }
  if (state === "rejected") return "Rejected";

  return sentenceCase(state);
};

export const consignmentStateDecider = (state, appraisalState, culprit) => {
  if (state === "waiting_for_appraisal") return "Appraisal Process";
  if (state === "appraisal_negotiation") {
    return "Appraisal Process";
  }
  if (state === "cancelled") return "Cancelled";
  if (state === "failed") {
    if (appraisalState === "rejected") {
      return culprit === "admin" ? "Offer Rejected" : "Rejected";
    }
    return "Failed";
  }
  if (state === "waiting_for_live") return "Price Confirmed";
  if (state === "on_sale") return "Item Active";
  if (
    state === "waiting_for_disbursement" ||
    state === "completed" ||
    state === "disburse_completed" ||
    "completed_without_deal"
  )
    return "Item Sold";
  if (state === "disburse_waiting_for_verification")
    return "Cashout in progress";
  return sentenceCase(state);
};

export const consignmentStatusDecider = (
  state,
  appraisalState,
  culprit,
  stateBefore
) => {
  if (state === "waiting_for_appraisal") return "Under appraisal process";
  if (state === "appraisal_negotiation") {
    if (appraisalState === "admin_offered" && stateBefore === "admin_pending")
      return "Admin submitted new offer\nWaiting for review";
    if (appraisalState === "customer_accepted")
      return "User accepted offer\nWaiting for admin confirmation";
    return "Admin submitted offer\nReady for review";
  }
  if (state === "cancelled") return "Cancelled";
  if (state === "failed") {
    if (appraisalState === "rejected")
      return culprit === "admin"
        ? "Counter offer rejected"
        : "User rejected offer";
    return "Failed";
  }
  if (state === "waiting_for_live") return "Item price is confirmed";
  if (state === "on_sale") return "Active and listed on apps ";
  if (
    state === "waiting_for_disbursement" ||
    state === "completed" ||
    state === "disburse_completed" ||
    "completed_without_deal"
  )
    return "Your item have been sold";
  if (state === "disburse_waiting_for_verification")
    return "Cashout in progress";
  return sentenceCase(state);
};

export const appraisalProgressTitle = (item) => {
  if (item?.state === "waiting_for_appraisal") return "Waiting for Review";
  if (item?.state === "appraisal_negotiation")
    return "Item confirmed by Belle&Kate Admin";
  if (item?.state === "admin_offered") return "Appraisal by Admin IDR ";
  if (item?.state === "admin_pending") return "User make an offer IDR ";
  if (item?.state === "customer_accepted") return "Offer accepted by User IDR ";
  if (item?.state === "admin_accepted") return "Offer accepted by Admin IDR ";
  if (item?.state === "rejected" && item?.submitted_by === "admin")
    return "Offer rejected by Admin IDR ";
  if (item?.state === "rejected" && item?.submitted_by === "customer")
    return "Offer rejected by User IDR ";
  if (item?.state === "consignment_in_progress")
    return "Consignment in progress by Admin";
  if (item?.state === "waiting_for_live") return "Waiting item to active";
  if (item?.state === "on_sale") return "Your item is active now!";
  if (item?.state === "completed_without_deal") return "Your item is sold!";
  if (item?.state === "waiting_for_disbursement")
    return "Your item is sold! We will prepare your fund for cashout.";
  if (item?.state === "completed") return "Cashout is ready";
  if (item?.state === "disburse_waiting_for_verification")
    return "Cashout is in progress";
  if (item?.state === "disburse_completed")
    return "We have transferred your funds.";

  return sentenceCase(item?.state);
};

export const sortingDecider = (data, condition) => {
  if (condition === "recommended")
    return data.sort((a, b) => {
      return a.id - b.id;
    });
  if (condition === "lowest")
    return data.sort((a, b) => {
      return a.product.selling_price - b.product.selling_price;
    });
  if (condition === "highest")
    return data.sort((a, b) => {
      return b.product.selling_price - a.product.selling_price;
    });
  if (condition === "newest")
    return data.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  if (condition === "oldest")
    return data.sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });

  return data;
};

export const filterQueriesDecider = (filter, sorting = "newest") => {
  let queries = [];
  const designers = filter
    .filter(({ section }) => section === "Designer")
    .map(({ id }) => id);
  const types = filter
    .filter(({ section }) => section === "Type")
    .map(({ name }) => name);
  const categories = filter
    .filter(({ section }) => section === "Category")
    // .map(({ id, name }) => (name === "Editor's Pick" ? name : id));
    .map(({ id, name }) => (name === "Editor's Pick" || name === "Vintage" ? name : id));
  const conditions = filter
    .filter(({ section }) => section === "Conditions")
    .map(({ id }) => id);
  const colours = filter
    .filter(({ section }) => section === "Colour")
    .map(({ name }) => name);

  if (designers.length) {
    designers.forEach((designerId) => {
      queries.push(`q[product_designer_id_in][]=${designerId}`);
    });
  }
  if (types.length) {
    types.forEach((typeName) => {
      queries.push(`q[product_product_styles_style_name_cont]=${typeName}`);
    });
  }
  if (conditions.length) {
    //change once provided by BE
    conditions.forEach((conditionId) => {
      queries.push(`q[product_condition_id_in][]=${conditionId}`);
    });
  }
  if (categories.length) {
    categories.forEach((categoryId) => {
      queries.push(
        categoryId === "Editor's Pick" || categoryId === "Vintage"
          ? `q[product_product_badges_badge_name_cont]=${categoryId}`
          : `q[product_category_id_in][]=${categoryId}`
      );
    });
  }
  if (colours.length) {
    colours.forEach((colorName) => {
      queries.push(`q[product_product_colours_colour_name_cont]=${colorName}`);
    });
  }
  queries.push(`sorting=${sorting}`);

  return queries.length ? queries.join("&") : null;
};

export const contactDecider = (value) => {
  if (value === "HermesAccessories") return "+6281310721386";
  if (value === "HermesBags" || value === "HermesConsignment") {
    const today = new Date().getDate();

    if (today <= 8) return "+6282298154428";
    if (today <= 16) return "+6281317653201";
    if (today <= 24) return "+6281212254912";
    return "+6281389561178";
  }
  if (value === "Watch") return "+6281212254912";
  if (value === "OtherBags") return "+6281212149175";
  if (value === "OtherConsignment") return "+6281806188011";
  if (value === "bababebi") return "+6282298154428";
  if (value === "entrupy") return "+6281212149175";
  if (value === "customer-care") return "+6281286095811";

  return "+6281806188011";
};

export const descriptionDecider = (value) => {
  if (value === "Hermes")
    return "founded in Paris in 1837 by Thierry Hermès. It is known for its high-quality leather goods highly coveted for their exquisite craftsmanship.";
  if (value === "Dior")
    return `Dior is a luxury fashion brand known for its sophisticated handbags, With signature design elements, such as the "Dior" logo hardware, Cannage stitching, and bold colors.`;
  if (value === "Louis Vuitton")
    return `founded in 1854 by Louis Vuitton. The brand's handbags are recognized for their distinctive design, featuring the signature "LV" monogram and the Damier canvas pattern.`;
  if (value === "Chanel")
    return "Chanel is a French luxury fashion house founded in 1910 by Coco Chanel, It is renowned for its timeless designs, as well as its signature quilted handbags";

  return "";
};

export const relationDecider = (name, values = {}) => {
  // if (name === "country" || name === "province" || name === "courier") {
  //   return true;
  // }
  // if (name === "city") {
  //   return values?.province?.id;
  // }
  if (name === "suburb") {
    return values?.city?.id;
  }
  if (name === "area") {
    return values?.suburb?.id;
  }
  return true;
};

export const selectionURLDecider = (name, id) => {
  if (name === "province") {
    return "/api/addresses/province";
  }
  // if (name === "city") {
  //   return `/api/addresses/city?province_id=${id}`;
  // }
  if (name === "suburb") {
    return `/api/addresses/suburb?city_id=${id}`;
  }
  if (name === "area") {
    return `/api/addresses/area?suburb_id=${id}`;
  }
  if (name === "courier") {
    return "/api/carts/couriers";
  }
  if (name === "designer") {
    return "/api/designers";
  }
  if (name === "condition") {
    return "/api/conditions";
  }
  if (name === "color") {
    return "/api/colours";
  }
  if (name === "currency") {
    return "/api/currencies";
  }
};

export const selectionDataChecker = (
  name,
  values = {},
  setValue = () => {}
) => {
  // if (name === "city" && values[name]?.id) {
  //   values?.province?.id !== values?.city?.province?.id && setValue(name, {});
  //   return;
  // }
  if (name === "suburb" && values[name]?.id) {
    values?.city?.id !== values?.suburb?.city?.id && setValue(name, {});
    return;
  }
  if (name === "area" && values[name]?.id) {
    values?.suburb?.id !== values?.area?.suburb?.id && setValue(name, {});
    return;
  }
  return;
};
