import React from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import AboutUs from "../assets/images/about-us.png";
import AboutUsPhone from "../assets/images/about-us-phone.png";
import About1 from "../assets/images/about-line-1.png";
import About2 from "../assets/images/about-line-2.png";
import About2New from "../assets/images/about-line-2-new.jpg";
import About3 from "../assets/images/about-line-3.png";
import colors from "../assets/colors/colors";
import { LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import Footer from "../components/Footer";
import Commitment from "../components/Home/Commitment";
import VisitUs from "../components/Home/VisitUs";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";

export default function AboutUsScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View>
      <ScrollView
        style={{ height: "100vh" }}
        showsVerticalScrollIndicator={false}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <Image
          source={isPhone ? AboutUsPhone : AboutUs}
          alt={"About Us"}
          style={{
            alignSelf: "center",
            width,
            height: isPhone ? 360 : (width * 600) / 1920,
            // marginRight: 12,
          }}
          defaultSource={require("../assets/images/spinning-loading.gif")}
          resizeMode={isPhone ? "cover" : "contain"}
        />
        <LatoRegular
          style={{
            fontSize: isPhone ? 24 : isTablet ? 48 : 60,
            lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
            marginTop: isPhone ? 48 : 100,
            marginBottom: 16,
            textAlign: "center",
          }}
        >
          RELEASE. RELOVE. REVOLVE.
        </LatoRegular>
        <LatoRegular
          style={{
            fontSize: isPhone ? 12 : 24,
            lineHeight: isPhone ? 16 : 32,
            textAlign: "center",
            color: colors.grey6C,
          }}
        >
          GIVE YOUR LUXURY GOODS A SECOND LIFE.
        </LatoRegular>
        <View
          style={{
            flexDirection: isPhone ? "column" : "row",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "center" : "space-between",
            marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 48 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <Image
            source={About1}
            alt={"A Trusted Marketplace"}
            style={{
              alignSelf: "center",
              width: isPhone ? containerWidth : (containerWidth - 40) / 2,
              height: isPhone
                ? (containerWidth * 281) / 358
                : (containerWidth - 40) / 2,
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
              marginLeft: isPhone ? 0 : 24,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              A TRUSTED MARKETPLACE
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginVertical: 32,
              }}
            >
              Our ROOM is a marketplace where members of our community can buy, consign, trade-in and sell luxury goods.
              We believe in giving high-end items a second life, offering people the chance to own their dream pieces
              while helping original owners get the most value from their investments.
            </LatoRegular>
            <Buttons
              label="SHOP COLLECTIONS"
              onPress={() => navigate("/products")}
              noBorder
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: isPhone ? "column" : "row-reverse",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "center" : "space-between",
            marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 24 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <Image
            source={About2New}
            alt={"A Trusted Marketplace"}
            style={{
              alignSelf: "center",
              width: isPhone ? containerWidth : (containerWidth - 40) / 2,
              height: isPhone
                ? (containerWidth * 281) / 358
                : (containerWidth - 40) / 2,
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
              marginRight: isPhone ? 0 : 24,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              HOW WE STARTED
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginVertical: 32,
              }}
            >
              Our founder, Ardine Nakety, found an opportunity to build
              Belle&Kate ROOM from her own personal experience. Her friends
              encountered difficulties in managing their luxury goods that they
              no longer use while wanting to find new luxury items for their
              wardrobe. From this problem, Ardine came up with a solution by
              creating a marketplace where people can #RELEASE and #RELOVE their
              luxury goods by allowing the luxury items to #REVOLVE.
            </LatoRegular>
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 24 : 100,
          }}
        >
          <Image
            source={isPhone ? AboutUsPhone : About3}
            alt={"A Trusted Marketplace"}
            style={{
              alignSelf: "center",
              width: containerWidth,
              height:
                (containerWidth * (isPhone ? 281 : 500)) /
                (isPhone ? 358 : 1460),
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: containerWidth,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 32,
              }}
            >
              HOW WE HAVE EVOLVED
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginVertical: 32,
              }}
            >
              Over the years, Belle&Kate has become the go-to trusted marketplace
              to buy, sell and trade amongst our community. In 2019, we opened
              our first showroom for our customers’ private viewing in PIK,
              North Jakarta and in 2021, relocated to the prestigious Fairgrounds at SCBD, South Jakarta.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginBottom: 32,
              }}
            >
              We strive to continue growing our community through intimate in
              store events and aim to give the best service from our dedicated
              client advisors towards our customers. Our personalized services,
              such as Home Shopping for VIP customers provides tailored advice
              on the best options for consignment, direct sale, or trade-in.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginBottom: 32,
              }}
            >
              As the years go by, we will keep expanding our stores to
              continuously reach new markets. Now, our newly expanded Jakarta
              store has been relaunched with more curated selections of
              pre-owned luxury goods, such as watches and vintage luxury goods. We expanded our
              presence to Singapore in August 2023, opening a new location in the prominent Orchard area at 313@somerset, Level B1.
            </LatoRegular>
          </View>
        </View>
        <Commitment />
        <VisitUs />
        <Footer />
      </ScrollView>
    </View>
  );
}
