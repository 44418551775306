import React, { useContext, useEffect, useState } from "react";
import { api } from "../../utils/api";
import ToasterContext from "../../contexts/ToastContext";
import { View, Text, FlatList, StyleSheet, ActivityIndicator, useWindowDimensions } from 'react-native';
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { useNavigate } from 'react-router-dom';
import { getDeviceType } from "../../tools/Interface";

const DesignerList = () => {
  const [designers, setDesigners] = useState([]);
  const [designerGroups, setDesignerGroups] = useState({});
  const [loading, setLoading] = useState(false);
  const { showToast } = useContext(ToasterContext);
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const excludeList = ['Hermes', 'Chanel', 'Dior', 'Louis Vuitton'];
  const navigate = useNavigate();

  const getDesigners = async () => {
    try {
      setLoading(true);
      const resDesigner = await api.get("/api/designers");

      const dataDesigner = resDesigner.data.data.map(designer => ({
        id: designer.id,
        name: designer.name
      }))
      .filter(designer => !excludeList.includes(designer.name)) 
      .sort((a, b) => a.name.localeCompare(b.name));

      setDesigners(dataDesigner);

      const groups = dataDesigner.reduce((acc, designer) => {
        const firstLetter = designer.name[0].toUpperCase();  // Get the first letter
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];  // Create a new group if it doesn't exist
        }
        acc[firstLetter].push(designer);
        return acc;
      }, {});

      setDesignerGroups(groups);

    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDesigners();
  }, []);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    item: {
      flex: 1,
      margin: 5,
      height: 100, // Fixed height for each item
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ddd', // Background color for each item
    },
    text: {
      fontSize: 16, // Font size for text inside each item
    },
  });

  const handleDesignerClick = (id, name) => {
    navigate(`/products/designer/${id}`

    , {
      state: {
        menu: [
          {
            name: name,
            section: "Designer",
            id: id,
          },
        ],
        designer_page: {
          title: name,
        },
      },
    }
    )
  };

  return (
    <>
      {loading ? (
        <ActivityIndicator
          color={colors.grey33}
          size={24}
        />
        ) : (
          <div>
              <div style={{ columnCount: isPhone ? 1 : 3, width: '100%', justifyContent:"space-between" }}>
                  {Object.keys(designerGroups).sort().map(letter => (
                      <div key={letter} style={{ breakInside: 'avoid', marginBottom: '20px' }}>
                        <LatoBold style={{ fontSize: isPhone ? 16 : 16, lineHeight: 28 }}>{letter}</LatoBold>
                        {designerGroups[letter].map(designer => (
                        <li key={designer.id} style={{ listStyleType: 'none' }}>
                            <LatoRegular 
                              style={{ fontSize: isPhone ? 16 : 16, lineHeight: 28, cursor: 'pointer'}}
                              onClick={() => handleDesignerClick(designer.id, designer.name)}
                            >
                              {designer.name}
                            </LatoRegular>
                        </li>
                        ))}
                      </div>
                  ))}
                </div>
          </div>
        )
      }
    </>
  )
};

export default DesignerList;
