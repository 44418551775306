import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  ScrollView,
  Animated,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import colors from "../assets/colors/colors";
import ProductGrid from "../components/Home/ProductGrid";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import FilterProduct from "../components/Catalog/FilterProduct";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../utils/api";
import { filterQueriesDecider } from "../tools/decider";
import Footer from "../components/Footer";
import ToasterContext from "../contexts/ToastContext";
import CatalogHeader from "../components/Catalog/CatalogHeader";

export default function ProductCatalogScreen() {
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { showToast } = useContext(ToasterContext);
  const scrollRef = useRef();
  const { discounted = false } = state || {};

  const filter = state?.menu
    ? typeof state?.menu === "string"
      ? [state?.menu]
      : state?.menu
    : [];

  const [shownSpecialFilter, setShownSpecialFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState((!id && filter) || []);
  const [shownFilter, setShownFilter] = useState(false);
  const [specialFilter, setSpecialFilter] = useState({
    title: "Newest",
    value: "newest",
  });
  const [productArr, setProductArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opacity, setOpacity] = useState(new Animated.Value(0));
  const [showHelp, setShowHelp] = useState(false);

  const designerArr = ["Hermes", "Chanel", "Dior", "Louis Vuitton"];
  const categoryArr = ["Bags", "Shoes", "Accessories", "Watch"];

  function isNumeric(value) {
    return !isNaN(Number(value));
  }

  const getData = async () => {
    try {
      setLoading(true);
      const isDesigner = pathname.includes('/designer/');
      const isCategory = pathname.includes('/category/');

      // const additionalQueries = filterQueriesDecider(
      //   id
      //     ? [
      //         ...(state?.menu || [
      //           {
      //             // id: designerArr.indexOf(id) + 1,
      //             id: isNumeric(id) ? id : designerArr.indexOf(id) + 1,
      //             name: id,
      //             section: "Designer",
      //             // section: "Category,"
      //           },
      //         ]),
      //         ...selected,
      //       ]
      //     : selected,
      //   specialFilter?.value
      // );


      const menuItems = isDesigner ? [
        ...(state?.menu || [
       {
        id: isNumeric(id) ? parseInt(id) : designerArr.indexOf(id) + 1,
        name: id,
        section: "Designer",
       },
      ]),
      ...selected,
     ] : (isCategory ? [
              ...(state?.menu || [
                {
                  id: categoryArr.includes(id) ? categoryArr.indexOf(id) + 1 : id,
                  name: id,
                  section: "Category",
                },
              ]),
              ...selected,
            ] : []);
  
      const additionalQueries = filterQueriesDecider(
        id ? [...menuItems, ...selected] : selected,
        specialFilter?.value
      );
      
      const res = await api.get(
        additionalQueries
          ? `/api/inventories/v2?${additionalQueries}${
              state?.querry ? "" : "&q[active_eq]=true"
            }&page=1&per_page=${
              isTablet && !isPhone ? 21 : 20
            }&q[product_product_styles_style_name_or_product_name_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${
              state?.querry || ""
            }${discounted ? "&q[product_discounted_eq]=1" : ""}`
          : `/api/inventories/v2?page=${page}&per_page=20${
              state?.querry ? "" : "&q[active_eq]=true"
            }&q[product_product_styles_style_name_or_product_name_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${
              state?.querry || ""
            }${discounted ? "&q[product_discounted_eq]=1" : ""}`
      );
      const data = res?.data?.data;

      setTotal(res?.data?.total);
      setProductArr(data);
      setPage(1);
      setShowHelp(false);

      Number(search.replace("?page=", "")) &&
        page !== Number(search.replace("?page=", "")) &&
        setPage(Number(search.replace("?page=", "")));

      page > 1 && !Number(search.replace("?page=", "")) && setPage(1);
    } catch (err) {
      console.log("err:", err.response);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetNext = async () => {

    const isDesigner = pathname.includes('/designer/');
    const isCategory = pathname.includes('/category/');

    try {
      setLoading(true);
      setPage(page + 1);

      const menuItems = isDesigner ? [
        ...(state?.menu || [
       {
        id: isNumeric(id) ? parseInt(id) : designerArr.indexOf(id) + 1,
        name: id,
        section: "Designer",
       },
      ]),
      ...selected,
     ] : (isCategory ? [
              ...(state?.menu || [
                {
                  id: categoryArr.includes(id) ? categoryArr.indexOf(id) + 1 : id,
                  name: id,
                  section: "Category",
                },
              ]),
              ...selected,
            ] : []);


      // const additionalQueries = filterQueriesDecider(
      //   id ? [...state?.menu, ...selected] : selected,
      //   specialFilter?.value
      // );

      const additionalQueries = filterQueriesDecider(
        id ? [...menuItems, ...selected] : selected,
        specialFilter?.value
      );

      const res = await api.get(
        additionalQueries
          ? `/api/inventories/v2?${additionalQueries}&page=${
              page + 1
            }&per_page=${isTablet && !isPhone ? 21 : 20}${
              state?.querry ? "" : "&q[active_eq]=true"
            }&q[product_product_styles_style_name_or_product_name_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${
              state?.querry || ""
            }${discounted ? "&q[product_discounted_eq]=1" : ""}`
          : `/api/inventories/v2?page=${page}&per_page=20${
              state?.querry ? "" : "&q[active_eq]=true"
            }&q[product_product_styles_style_name_or_product_name_or_product_category_name_or_product_designer_name_or_product_product_badges_badge_name_cont]=${
              state?.querry || ""
            }${discounted ? "&q[product_discounted_eq]=1" : ""}`
      );
      const data = res?.data?.data;

      setTotal(res?.data?.total);
      setProductArr([...productArr, ...data]);
    } catch (err) {
      console.log("err:", err.response);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProductArr([]);
    getData();
    scrollRef?.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [specialFilter, selected, id, state, width]);

  const specialFilterArr = [
    {
      title: "Recommended",
      value: "recommended",
    },
    {
      title: "Price low to high",
      value: "price-low-high",
    },
    {
      title: "Price high to low",
      value: "price-high-low",
    },
    {
      title: "Newest",
      value: "newest",
    },
  ];

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const animate = (easing, close) => {
    opacity.setValue(close ? 1 : 0);
    Animated.timing(opacity, {
      toValue: close ? 0 : 1,
      duration: isPhone ? 200 : 500,
      easing,
      useNativeDriver: true,
    }).start();
  };

  const animatedWidth = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, isPhone ? width : 450],
  });

  return (
    <>
      <View>
        <ScrollView
          scrollEventThrottle={16}
          onScroll={(e) => {
            const layout = e.nativeEvent.contentSize.height;
            const yPosition = e.nativeEvent.contentOffset.y;

            if (
              total > productArr.length &&
              !loading &&
              yPosition > layout - 2000
            ) {
              setLoading(true);
              handleGetNext();
            }

            if (page % 3 === 0 && yPosition > layout - 2000) {
              setShowHelp(true);
            }
          }}
          ref={scrollRef}
          style={{ height: height }}
        >
          <Navbar
            backgroundColor={colors.whiteFF}
            showHelp={showHelp}
            setShowHelp={setShowHelp}
          />
          <CatalogHeader
            total={total}
            setShownFilter={setShownFilter}
            setShownSpecialFilter={setShownSpecialFilter}
            shownFilter={shownFilter}
            shownSpecialFilter={shownSpecialFilter}
            specialFilter={specialFilter}
            animate={animate}
            selected={selected}
          />
          {Boolean(shownSpecialFilter) && (
            <View
              style={{
                position: "absolute",
                right: gap,
                top:
                  id && state?.menu[0]?.section === "Designer"
                    ? ((containerWidth / 2) * 475) / 730 +
                      (isPhone ? 205 : isTablet ? 260 : 330)
                    : isTablet
                    ? 260
                    : 330,
                // height: 100,
                width: 200,
                borderWidth: 1,
                backgroundColor: colors.whiteFF,
                zIndex: 5,
                paddingHorizontal: 8,
                paddingVertical: 6,
                borderRadius: 8,
                borderColor: colors.grey90,
              }}
            >
              <LatoRegular
                style={{
                  paddingVertical: 12,
                  marginBottom: 6,
                  borderBottomWidth: 1,
                  borderBottomColor: colors.greyE6,
                }}
              >
                SORT BY
              </LatoRegular>
              {specialFilterArr?.map((item, idx) => {
                return (
                  <TouchableOpacity
                    key={idx}
                    onPress={() => {
                      setSpecialFilter(item);
                      setPage(1);
                      navigate(id ? `/products/designer/${id}` : `/products`, {
                        state: {
                          menu: state?.menu,
                          designer_page: state?.designer_page,
                          querry: state?.querry,
                        },
                      });
                      setShownSpecialFilter(false);
                    }}
                    style={{
                      paddingVertical: 12,
                      backgroundColor:
                        specialFilter.value === item?.value
                          ? colors.transparentGrey6C
                          : colors.whiteFF,
                      marginHorizontal: -8,
                      paddingHorizontal: 8,
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        textTransform: "uppercase",
                      }}
                    >
                      {item?.title}
                    </LatoRegular>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <ProductGrid loading={loading} catalog wrap data={productArr} />
          </View>
          <Footer />
        </ScrollView>
      </View>
      {Boolean(shownFilter) && (
        <FilterProduct
          selected={selected}
          setSelected={setSelected}
          shownFilter={shownFilter}
          setShownFilter={setShownFilter}
          isDesignerPage={id}
          animatedWidth={animatedWidth}
          opacity={opacity}
          setPage={setPage}
        />
      )}
    </>
  );
}
