import React, { useState, useEffect } from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoLight, LatoRegular, LatoBold } from "../components/Text";
import Request from "../assets/images/request.png";
import ContactUsPhone from "../assets/images/contact-us-phone.png";
import SocialMedia from "../components/SocialMedia";
import { getDeviceType } from "../tools/Interface";
import { Wrapper } from "../components/Wrapper";
import Footer from "../components/Footer";
import { BsWhatsapp } from "react-icons/bs";
import { contactDecider } from "../tools/decider";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";
import DesignerList from "../components/Catalog/DesignersList";
import Hermes from "../assets/images/hermes-cover.png";
import Chanel from "../assets/images/chanel-cover.png";
import LV from "../assets/images/lv-cover.png";
import Dior from "../assets/images/dior-cover.png";
import { identity } from "lodash"
// import { ScrollView } from "react-native-web";

export default function DesignersScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });


  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const number = contactDecider("HermesConsignment");
    setPhoneNumber(number);
  }, []);

  

  const handleDesignerClick = (id) => {
    const designerArr = ["Hermes", "Chanel", "Dior", "Louis Vuitton"];
    navigate(`/products/designer/${id}`, {
        state: {
          menu: [
            {
              name: id,
              section: "Designer",
              id: designerArr.indexOf(id) + 1,
            },
          ],
          designer_page: {
            title: id,
            image:
              id === "Chanel"
                ? Chanel
                : id === "Louis Vuitton"
                ? LV
                : id === "Dior"
                ? Dior
                : Hermes,
          },
        },
      })
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <View style={{ marginBottom:100, marginVertical: 48,
                marginHorizontal: gap,
                justifyContent: "center",
                alignItems: "center", }}>
            <LatoRegular style={{ fontSize: isPhone ? 24 : 48, lineHeight: isPhone ? 36 : 56, marginBottom: 56, textAlign: 'center'}} >
                <span>DISCOVER LUXURY</span>{isPhone ? <br/> : ' '}<span>DESIGNER BRANDS</span>
            </LatoRegular>
            <View style={{flexDirection: isPhone ? "column" : "row", alignItems: 'flex-start', width:'100%', gap: isPhone ? 40 : 0}}>
                <View style={{gap:8, width: isPhone ? "100%" : ''}}>
                    <View style={{width:"100%", justifyContent:"center", alignItems:"start", borderBottomWidth:1, borderColor:"#020202"}}>
                        <LatoBold style={{ fontSize: isPhone ? 16 : 20, textAlign: "left", paddingVertical:8 }}>POPULAR DESIGNERS</LatoBold>
                    </View>
                    <View style={{ fontSize:16, lineHeight:24, gap:4 }}>
                        <LatoRegular style={{ fontSize: isPhone ? 16 : 16, lineHeight:28, cursor: 'pointer' }} onClick={() => handleDesignerClick("Hermes")} >Hermes</LatoRegular>
                        <LatoRegular style={{ fontSize: isPhone ? 16 : 16, lineHeight:28, cursor: 'pointer' }} onClick={() => handleDesignerClick("Chanel")}>Chanel</LatoRegular>
                        <LatoRegular style={{ fontSize: isPhone ? 16 : 16, lineHeight:28, cursor: 'pointer' }} onClick={() => handleDesignerClick("Dior")}>Dior</LatoRegular>
                        <LatoRegular style={{ fontSize: isPhone ? 16 : 16, lineHeight:28, cursor: 'pointer' }} onClick={() => handleDesignerClick("Louis Vuitton")}>Louis Vuitton</LatoRegular>
                    </View>
                </View>
                {/* Conditional rendering of the divider */}
                {!isPhone && <View style={{ width: 1, height: '100%', backgroundColor: '#FFFFFF', marginHorizontal: 40 }} />}
                <View style={{flex:1, gap:12, width:"100%"}}>
                    <View style={{width:"100%", justifyContent:"center", alignItems:"start", borderBottomWidth:1, borderColor:"#020202"}}>
                        <LatoBold style={{ fontSize: isPhone ? 16 : 20, textAlign: "left", paddingVertical:8 }}>BRANDS A-Z</LatoBold>
                    </View>
                    {/* <LatoRegular style={{ fontSize: isPhone ? 24 : 20 }}>BRANDS A-Z</LatoRegular> */}
                    <DesignerList />
                </View>
            </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
